import React, { useState, useEffect } from "react";
import { HiOutlineArrowSmUp } from "react-icons/hi";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="fixed right-12 bottom-12 z-20">
      <button
        type="button"
        className={`flex justify-center items-center w-16 h-16 bg-accent-1 rounded-full shadow-xl transition ease-in-out duration-500 ${
          isVisible ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={scrollToTop}
      >
        <HiOutlineArrowSmUp size={40} className="text-ink-1" />
      </button>
    </div>
  );
}

export default ScrollToTopButton;
