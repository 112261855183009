import React from "react";

function Container({ children }) {
  return (
    <div className="h-full max-w-7xl w-10/12 md:w-11/12 mx-auto">
      {children}
    </div>
  );
}

export default Container;
