import React from "react";
import { Link } from "gatsby";

function NavLink({ name, url, path, toggleSidebar }) {
  return (
    <li>
      <Link
        to={`${path === "/" ? url : `/${url}`}`}
        className="uppercase hover:text-link transition-colors duration-300"
        onClick={toggleSidebar}
      >
        {name}
      </Link>
    </li>
  );
}

export default NavLink;
