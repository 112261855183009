import { Link } from "gatsby";
import React from "react";
import { IoCloseOutline } from "react-icons/io5";

import NavLink from "./NavLink";

function Sidebar({ links, path, showSidebar, toggleSidebar }) {
  return (
    <>
      <div
        className={`fixed inset-0 md:hidden bg-overlay transition duration-300 z-30 ${
          showSidebar ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      ></div>
      <aside
        className={`fixed md:hidden top-0 right-0 flex justify-center items-center bg-white min-h-screen max-w-xs w-full transition duration-300 z-30 ${
          showSidebar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          type="button"
          className={`absolute top-5 right-5 transform hover:rotate-180 transition duration-300`}
          onClick={toggleSidebar}
        >
          <IoCloseOutline size={55} />
        </button>
        <ul className="grow flex flex-col justify-center items-center gap-10 text-lg font-semibold text-ink-4 tracking-wider">
          {links.map((link, index) => (
            <NavLink
              key={index}
              {...link}
              path={path}
              toggleSidebar={toggleSidebar}
            />
          ))}
          <li>
            <Link
              to="/contact-us"
              className="py-3 px-6 rounded-3xl text-white bg-link"
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
