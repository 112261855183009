import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import NavLink from "./NavLink";
import HamburgerLogo from "../assets/images/hamburger.svg";

function Navbar({ links, path, toggleSidebar }) {
  return (
    <nav>
      <div className="flex justify-between items-center max-w-7xl w-11/12 mx-auto py-3">
        <Link to="/">
          <StaticImage
            src="../assets/images/DP_logo.png"
            alt="Data Prime Logo"
            height={60}
            placeholder="none"
          />
        </Link>
        <button
          type="button"
          className="px-3 md:hidden"
          onClick={toggleSidebar}
        >
          <img src={HamburgerLogo} alt="Navbar Menu" />
        </button>
        <ul className="hidden md:flex items-center gap-x-8 text-sm lg:text-base text-ink-4 font-semibold tracking-wider">
          {links.map((link, index) => (
            <NavLink key={index} {...link} path={path} />
          ))}
          <li>
            <Link
              to="/contact-us"
              className="py-3 px-6 rounded-3xl text-white bg-link"
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
