import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import ScrollToTopButton from "./ScrollToTopButton";
import Footer from "./Footer";

export const query = graphql`
  {
    allNavbarLinkJson {
      nodes {
        name
        url
      }
    }
  }
`;

function Layout({ children, path }) {
  const [showSidebar, setShowSidebar] = useState(false);

  const data = useStaticQuery(query);
  const links = data.allNavbarLinkJson.nodes;

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <main className="flex flex-col min-h-screen">
      <Navbar links={links} path={path} toggleSidebar={toggleSidebar} />
      <Sidebar
        links={links}
        path={path}
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
      />
      <ScrollToTopButton />
      {children}
      <Footer />
    </main>
  );
}

export default Layout;
