import React from "react";

function Footer() {
  return (
    <footer className="bg-[#002E4E] py-4">
      <p className="text-ink-3 text-sm text-center">
        All rights reserved. {new Date().getFullYear()} &copy; Dataprime Sdn Bhd
      </p>
    </footer>
  );
}

export default Footer;
